











/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import { mapMutations, mapState } from "vuex";

import MessageList from "./Messages/MessageList.vue";
import PersonList from "./PersonList.vue";

export default Vue.extend({
  name: "crm-sms",
  components: { MessageList, PersonList },

  data() {
    return {
      contact: null,
    };
  },
  computed: {
    ...mapState("crmSMSModule", ["contactSelect"]),
    ...mapState(["userContacts"]),
  },
  methods: {
    ...mapMutations("crmSMSModule", [
      "mutSetMessages",
      "mutcontacts",
      "mutSetContact",
    ]),
  },

  mounted() {
    document
      .getElementsByTagName("html")[0]
      .setAttribute("style", "overflow-y: hidden;");
  },
  beforeDestroy() {
    document.getElementsByTagName("html")[0].setAttribute("style", "");
  },
  destroyed() {
    this.mutSetMessages([]);
    this.mutcontacts([]);
  },
});
